<template>
  <div>
    <SectionTitle title="Dados da entrega" />

    <v-row dense>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          :disabled="!showEditOrders"
          v-model="order.deadline"
          label="Prazo de entrega"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          rounded
          outlined
          dense
          hide-details
          :readonly="is_disabled"
          @blur="cleanDeadline"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          :disabled="!showEditOrders"
          v-mask="'##/##/####'"
          v-model="order.delivery_date"
          label="Data da entrega"
          :readonly="is_disabled"
          placeholder="Dia/Mês/Ano"
          rounded
          outlined
          dense
          hide-details
          :rules="[(v) => !!v || 'Campo obrigatório']"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          :disabled="!showEditOrders"
          v-model="order.delivery_time"
          label="Horário de entrega"
          rounded
          outlined
          dense
          hide-details
          :readonly="is_disabled"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        md="5"
      >
        <v-select
          v-model="order.shipping"
          :disabled="!showEditOrders"
          label="Frete"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :items="shippings ? shippings : getShippings()"
          :item-value="'name'"
          :item-text="'name'"
          rounded
          dense
          hide-details
          outlined
          :readonly="is_disabled"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="order.parking"
          :disabled="!showEditOrders"
          label="Estacionamento"
          rounded
          outlined
          dense
          hide-details
          :items="parkings ? parkings : getParkings()"
          :item-value="'name'"
          :item-text="'name'"
          :readonly="is_disabled"
        />
      </v-col>
    </v-row>
    <h6 class="subtitle-2 secondary--text my-3">Local da entrega</h6>

    <v-row dense>
      <v-col
        cols="12"
        md="9"
      >
        <v-item-group
          mandatory
          v-model="order.address"
        >
          <v-container>
            <v-row>
              <v-col
                v-for="n in order.budget.client.client_address"
                :key="n"
                cols="12"
                md="4"
              >
                <v-item
                  v-slot="{ active, toggle }"
                  v-if="order.address == n.id"
                >
                  <v-card
                    :class="active ? 'pa-3 u-border-primary' : 'pa-3'"
                    min-height="180"
                    @click="toggle"
                  >
                    <v-chip
                      class="primary"
                      v-if="n.isDelivery"
                      >Endereço de Entrega</v-chip
                    >
                    <ul>
                      <li>
                        <span class="subtitle-2 secondary--text"
                          >Endereço:
                        </span>
                        <span class="body-2 secondary--text">{{
                          n.address
                        }}</span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">N°: </span>
                        <span class="body-2 secondary--text">{{
                          n.number
                        }}</span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">Bairro: </span>
                        <spam class="body-2 secondary--text">{{
                          n.district
                        }}</spam>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">Cidade: </span>
                        <spam class="body-2 secondary--text">{{ n.city }}</spam>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">CEP: </span>
                        <spam class="body-2 secondary--text">{{ n.cep }}</spam>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">Estado: </span>
                        <spam class="body-2 secondary--text">{{ n.uf }}</spam>
                      </li>
                    </ul>
                  </v-card>
                </v-item>
                <v-item
                  v-slot="{ active, toggle }"
                  v-else
                >
                  <v-card
                    :class="active ? 'pa-3 u-border-primary' : 'pa-3'"
                    min-height="180"
                    @click="toggle"
                  >
                    <v-chip
                      class="primary mb-2"
                      small
                      outlined
                      v-if="n.isDelivery"
                    >
                      Endereço de Entrega
                    </v-chip>
                    <ul v-if="n.isDelivery">
                      <li>
                        <span class="subtitle-2 secondary--text"
                          >Razão Social / Nome:
                        </span>
                        <span class="body-2 secondary--text">
                          {{ n.name }}
                        </span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">
                          Telefone:
                        </span>
                        <span class="body-2 secondary--text">
                          {{ n.phone }}
                        </span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">
                          CPF / CNPJ:
                        </span>
                        <span class="body-2 secondary--text">
                          {{ n.cpf_cnpj }}
                        </span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">
                          Inscrição Estadual / RG:
                        </span>
                        <span class="body-2 secondary--text">
                          {{ n.ie_rg }}
                        </span>
                      </li>
                    </ul>
                    <v-divider
                      class="my-4"
                      v-if="n.isDelivery"
                    />
                    <ul>
                      <li>
                        <span class="subtitle-2 secondary--text"
                          >Endereço:
                        </span>
                        <span class="body-2 secondary--text">{{
                          n.address
                        }}</span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">N°: </span>
                        <span class="body-2 secondary--text">{{
                          n.number
                        }}</span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">Bairro: </span>
                        <span class="body-2 secondary--text">{{
                          n.district
                        }}</span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">Cidade: </span>
                        <span class="body-2 secondary--text">{{ n.city }}</span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">CEP: </span>
                        <span class="body-2 secondary--text">{{ n.cep }}</span>
                      </li>
                      <li>
                        <span class="subtitle-2 secondary--text">Estado: </span>
                        <span class="body-2 secondary--text">{{ n.uf }}</span>
                      </li>
                    </ul>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-col>
    </v-row>

    <SectionTitle
      v-if="showOrderPaymentData"
      title="Dados do pagamento"
    />

    <v-row
      v-if="showOrderPaymentData"
      dense
    >
      <v-col
        cols="12"
        md="5"
      >
        <v-text-field
          :disabled="!showEditOrders"
          v-model="order.suframa"
          label="Suframa"
          placeholder="( AM | RR | AP | RO )"
          rounded
          outlined
          dense
          hide-details
          :readonly="is_disabled"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          :disabled="!showEditOrders"
          v-model="order.payment_type"
          label="Forma de pagamento"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :items="paymentForms ? paymentForms : getPayments()"
          :item-value="'name'"
          :item-text="'name'"
          rounded
          dense
          hide-details
          outlined
          :readonly="is_disabled"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    shippings: Array,
    paymentForms: Array,
    parkings: Array,
  },
  data() {
    return {
      is_disabled: null,
    }
  },
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
  },
  computed: {
    ...mapGetters('comercial', ['showEditOrders', 'showOrderPaymentData']),
  },
  methods: {
    setIdsToAddress() {
      var i = 0
      this.order.budget_info.client.client_address.map((item) => {
        item.id = i
        i++
      })
    },
    stripHtml(html) {
      const tmp = document.createElement('div')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    cleanDeadline() {
      this.order.deadline = this.stripHtml(this.order.deadline.trim())
    },
  },
  watch: {
    'order.deadline': function (newVal) {
      this.order.deadline = this.stripHtml(newVal)
    },
  },
  created() {
    this.is_disabled = this.$cookies.get('profile') == 5 ? true : false
    this.setIdsToAddress()
    this.order.address = parseInt(this.order.address)

    if (!this.order.deadline) {
      this.order.deadline = this.order.budget_info.validate_budget
    }

    this.order.deadline = this.stripHtml(this.order.deadline)

    if (!this.order.shipping) {
      this.order.shipping = this.order.budget_info.shipping
    }

    if (!this.order.payment_type) {
      this.order.payment_type = this.order.budget_info.payment
    }
  },
}
</script>

<style lang="scss">
.u-border-primary {
  border: 2px solid $primary !important;
}
</style>
